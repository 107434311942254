import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import './table.scss';
import Modal from './Modal/Modal';
import nodata from '../../../auth/nodata.png'
import { calculateScore, calculateTotalScore } from '../../../utils/scoreUser';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ProgressBarComponent from './Progress/Progress';
const ContentTable = ({ setShowModal, showModal, searchQuery }) => {
    const [currentPageTotalScore, setCurrentPageTotalScore] = useState(0);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [errors, setErrors] = useState({});
    const [exerciseOptions, setExerciseOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'right',
    });
    const { vertical, horizontal, open } = state;
    const [formData, setFormData] = useState({

        fullName: '',
        ageGroup: '',
        groupType: '',
        exercises: [
            { exerciseName: '', result: '' },
            { exerciseName: '', result: '' },
            { exerciseName: '', result: '' }
        ]
    });
    const monthNames = {
        "01": "Yanvar", "02": "Fevral", "03": "Mart", "04": "Aprel", "05": "May",
        "06": "Iyun", "07": "Iyul", "08": "Avgust", "09": "Sentyabr", "10": "Oktabr", "11": "Noyabr", "12": "Dekabr"
    };
    const parseMonth = useCallback((dateString) => {
        if (!dateString) return null;
        const [day, month] = dateString.split(' ');
        const months = {
            'January': '01', 'February': '02', 'March': '03', 'April': '04', 'May': '05',
            'June': '06', 'July': '07', 'August': '08', 'September': '09', 'October': '10',
            'November': '11', 'December': '12'
        };

        return months[month] || null;
    }, []);

    const filteredData = useMemo(() => {
        return data.filter((item) => {
            const itemMatchesMonth = selectedMonth ? parseMonth(item.createdAt) === selectedMonth : true;
            const itemMatchesSearch = searchQuery
                ? item.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.ageGroup.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.exercises.some(exercise =>
                    exercise.exerciseName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    exercise.result.toLowerCase().includes(searchQuery.toLowerCase())
                )
                : true;
            return itemMatchesMonth && itemMatchesSearch;
        });
    }, [data, selectedMonth, searchQuery, parseMonth]);

    useEffect(() => {
        const startIdx = page * rowsPerPage;
        const endIdx = startIdx + rowsPerPage;
        const currentPageData = data.slice(startIdx, endIdx);

        const totalScore = currentPageData.reduce((total, item) => total + item.score, 0);
        setCurrentPageTotalScore(totalScore);
    }, [page, data, rowsPerPage]);

    const fetchData = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get('https://api.jismoniy-tayyorgarlik.uz/7cb5ca1f27c46c321a1a2fd74cc985b31b4db13459882a9ebd56a5508c745905', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            // Calculate and assign scores
            const dataWithScores = response.data.map(item => ({
                ...item,
                exercises: item.exercises.map(exercise => ({
                    ...exercise,
                    score: calculateScore(exercise.exerciseName, exercise.result, item.ageGroup) // Har bir mashq uchun ball
                })),
                score: calculateTotalScore(item.ageGroup, item.exercises) // Umumiy ball
            }));
            setData(dataWithScores);
        } catch (error) {
            console.error('Error fetching data', error);
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized: Please login again.');
            }
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        const exercisesByGroup = {
            'Maxsus bo\'linma': {
                'I': ['2-Mashq', '5b-Mashq', '26-Mashq', '9-Mashq', '6a-Mashq', '7a-Mashq'],
                'II': ['2-Mashq', '5b-Mashq', '26-Mashq', '9-Mashq', '6a-Mashq', '7a-Mashq'],
                'III': ['2-Mashq', '5b-Mashq', '26-Mashq', '9-Mashq', '6a-Mashq', '7a-Mashq'],
                'IV': ['2-Mashq', '5b-Mashq', '4-Mashq', '9-Mashq', '10-Mashq'],
                'V': ['2-Mashq', '9-Mashq', '4-Mashq'],
                'VI': ['2-Mashq', '9-Mashq', '4-Mashq'],
                'VII': ['2-Mashq', '9-Mashq', '4-Mashq']
            },
            'Umum qo\'shin bo\'linma': {
                'I': ['2-Mashq', '5b-Mashq', '9-Mashq', '10-Mashq', '26-Mashq'],
                'II': ['2-Mashq', '5b-Mashq', '9-Mashq', '10-Mashq', '26-Mashq'],
                'III': ['2-Mashq', '5b-Mashq', '9-Mashq', '10-Mashq', '26-Mashq'],
                'IV': ['2-Mashq', '4-Mashq', '9-Mashq'],
                'V': ['2-Mashq', '4-Mashq', '9-Mashq'],
                'VI': ['2-Mashq', '4-Mashq', '9-Mashq'],
                'VII': ['2-Mashq', '4-Mashq', '9-Mashq']
            }
        };

        const selectedExercises = exercisesByGroup[formData.groupType]?.[formData.ageGroup] || [];
        setExerciseOptions(selectedExercises);
    }, [formData.ageGroup, formData.groupType]);

    const handleMonthChange = useCallback((e) => {
        setSelectedMonth(e.target.value);
    }, []);

    const handleChangePage = useCallback((newPage) => {
        setPage(newPage);
    }, []);

    const renderPageNumbers = () => {
        const totalPages = Math.ceil(filteredData.length / rowsPerPage);
        return Array.from({ length: totalPages }, (_, i) => (
            <button
                key={i}
                onClick={() => handleChangePage(i)}
                className={i === page ? 'active' : 'page'}
            >
                {i + 1}
            </button>
        ));
    };

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        const regex = /exercises\[(\d+)\]\.(\w+)/;
        const match = name.match(regex);

        if (match) {
            const [index, field] = [parseInt(match[1]), match[2]];
            setFormData(prevFormData => {
                const newExercises = [...prevFormData.exercises];
                newExercises[index][field] = value;
                return { ...prevFormData, exercises: newExercises };
            });
        } else {
            setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
        }
    }, []);

    const handleClick = (newState) => () => {
        setState({ ...newState, open: true });
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };
    const calculateGrade = useCallback((score) => {
        if (score >= 60) return 5;
        if (score >= 40) return 4;
        if (score >= 18) return 3;
        return 2;
    }, []);
    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                const updatedFormData = {
                    ...formData,
                    exercises: formData.exercises.map(exercise => ({
                        ...exercise,
                        score: calculateScore(exercise.exerciseName, exercise.result, formData.ageGroup)
                    })),
                    score: calculateTotalScore(formData.ageGroup, formData.exercises),
                    grade: calculateGrade(calculateTotalScore(formData.ageGroup, formData.exercises))
                };

                await axios.post('https://api.jismoniy-tayyorgarlik.uz/9c22468b26fa70722c3ba99a870eb3bc8cbf1315aeb2b0cefed6b731469d0bae', updatedFormData, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                fetchData();
                setFormData({
                    fullName: '', ageGroup: '', groupType: '',
                    exercises: [
                        { exerciseName: '', result: '' },
                        { exerciseName: '', result: '' },
                        { exerciseName: '', result: '' }
                    ]
                });
                setShowModal(false);
                setState({ open: true, vertical: 'top', horizontal: 'right' });
            } catch (error) {
                console.error('Error submitting form', error);
            } finally {
                setLoading(false);
            }
        }
    }, [formData, fetchData, setShowModal, calculateGrade]);

    const validateForm = useCallback(() => {
        const tempErrors = {};
        if (!formData.fullName) tempErrors.fullName = 'F.I.Sh ni to\'ldiring';
        if (!formData.ageGroup) tempErrors.ageGroup = 'Yosh guruhini tanlang';
        if (!formData.groupType) tempErrors.groupType = 'Bo\'linma turini tanlang';

        formData.exercises.forEach((exercise, index) => {
            if (!exercise.exerciseName) tempErrors[`exercises.${index}.exerciseName`] = 'Mashq turini tanlang';
            if (!exercise.result) tempErrors[`exercises.${index}.result`] = 'Natijani kiriting';
        });

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    }, [formData]);



    return (


        <div className='table-container animate__animated animate__fadeIn'>
            {/* <ProgressBarComponent /> */}
            <table>
                <thead>
                    <tr>
                        <th>№</th>
                        <th>F.I.Sh</th>
                        <th>Yosh guruhi</th>
                        <th colSpan={3} className='execrise'>Mashq / Ballar</th>
                        <th>Ball</th>
                        <th>Baho</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.length > 0 ? (
                        filteredData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <tr key={row._id}>
                                    <td>{page * rowsPerPage + index + 1}</td>
                                    <td>{row.fullName}</td>
                                    <td>{row.ageGroup}</td>
                                    {row.exercises.map((exercise, i) => (
                                        <td key={i}>
                                            <div className="exercise-cell">
                                                <span className="exercise-name">{exercise.exerciseName}</span>
                                                <div>
                                                    <span className="exercise-result">{exercise.result}</span>
                                                    <span>/</span>
                                                    <span className="exercise-score">{exercise.score}</span>
                                                </div>
                                            </div>
                                        </td>
                                    ))}
                                    <td>{row.score}</td>
                                    <td>{calculateGrade(row.score)}</td>
                                </tr>
                            ))
                    ) : (
                        <tr>
                            <td colSpan="8" style={{ textAlign: 'center' }}>
                                <img className='nodata' src={nodata} alt="" />
                                <p className='nodata-text'>
                                    Ma'lumot topilmadi
                                </p>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <div className="table-actions">

                <div className="tablePath">
                    <h4>{selectedMonth ? `${monthNames[selectedMonth]} oyi ma'lumotlari` : "Barcha ma'lumotlar"}</h4>
                </div>
                <div className='filterTable'>
                    <p>Ma'lumotlarni saralash</p>
                    <select value={selectedMonth} onChange={handleMonthChange}>
                        <option value="">Barchasi</option>
                        <option value="01">Yanvar</option>
                        <option value="02">Fevral</option>
                        <option value="03">Mart</option>
                        <option value="04">Aprel</option>
                        <option value="05">May</option>
                        <option value="06">Iyun</option>
                        <option value="07">Iyul</option>
                        <option value="08">Avgust</option>
                        <option value="09">Sentyabr</option>
                        <option value="10">Oktabr</option>
                        <option value="11">Noyabr</option>
                        <option value="12">Dekabr</option>
                    </select>

                </div>
            </div>
            <div className="pagination">
                <button
                    onClick={() => handleChangePage(Math.max(page - 1, 0))}
                    disabled={page === 0}
                >
                    <i className='bx bx-chevron-left' ></i>
                </button>
                {renderPageNumbers()}
                <button
                    onClick={() => handleChangePage(Math.min(page + 1, Math.ceil(filteredData.length / rowsPerPage) - 1))}
                    disabled={page === Math.ceil(filteredData.length / rowsPerPage) - 1}
                >
                    <i className='bx bx-chevron-right'></i>
                </button>
            </div>
            {showModal && (
                <Modal show={showModal} onClose={() => setShowModal(false)}>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2>Ma'lumot kiritish</h2>
                                <button className="close-button" onClick={() => setShowModal(false)}>&times;</button>
                            </div>
                            <div className="modal-body">
                                <div className="first-form">
                                    <div className="form-group">
                                        <label>F.I.Sh</label>
                                        <input
                                            type="text"
                                            name="fullName"
                                            value={formData.fullName}
                                            onChange={handleChange}
                                            className={errors.fullName ? 'error' : ''}
                                        />
                                        {errors.fullName && <span className="error-text">{errors.fullName}</span>}
                                    </div>
                                    <div className="form-group">
                                        <label>Yosh Guruhi</label>
                                        <select
                                            name="ageGroup"
                                            value={formData.ageGroup}
                                            onChange={handleChange}
                                            className={errors.ageGroup ? 'error' : ''}
                                        >
                                            <option value="">Tanlash</option>
                                            <option value="I">I</option>
                                            <option value="II">II</option>
                                            <option value="III">III</option>
                                            <option value="IV">IV</option>
                                            <option value="V">V</option>
                                            <option value="VI">VI</option>
                                            <option value="VII">VII</option>
                                        </select>
                                        {errors.ageGroup && <span className="error-text">{errors.ageGroup}</span>}
                                    </div>
                                </div>
                                <label>Bo'linma turi</label>
                                <select
                                    name="groupType"
                                    value={formData.groupType}
                                    onChange={handleChange}
                                    className={errors.groupType ? 'error' : ''}>
                                    <option value="">Tanlash</option>
                                    <option value="Maxsus bo'linma">Maxsus bo'linma</option>
                                    <option value="Umum qo'shin bo'linma">Umum qo'shin bo'linma</option>
                                </select>
                                {formData.exercises.map((exercise, index) => (
                                    <div className="second-form" key={index}>
                                        <div className="form-group">
                                            <label>{index + 1}-Mashq turi</label>
                                            <select
                                                name={`exercises[${index}].exerciseName`}
                                                value={exercise.exerciseName}
                                                onChange={handleChange}
                                                className={errors[`exercises.${index}.exerciseName`] ? 'error' : ''}
                                            >
                                                <option value="">Tanlash</option>
                                                {exerciseOptions.map((exerciseName, i) => (
                                                    <option key={i} value={exerciseName}>{exerciseName}</option>
                                                ))}
                                            </select>
                                            {errors[`exercises.${index}.exerciseName`] && (
                                                <span className="error-text">{errors[`exercises.${index}.exerciseName`]}</span>
                                            )}
                                        </div>

                                        <div className="form-group">
                                            <label>Natija</label>
                                            <input
                                                type="text"
                                                name={`exercises[${index}].result`}
                                                value={exercise.result}
                                                onChange={handleChange}
                                                className={errors[`exercises.${index}.result`] ? 'error' : ''}
                                            />
                                            {errors[`exercises.${index}.result`] && (
                                                <span className="error-text">{errors[`exercises.${index}.result`]}</span>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="modal-footer">
                                <div className="modal-actions">
                                    <button type="button" onClick={() => setShowModal(false)}>Bekor qilish</button>
                                    <button type="submit" disabled={loading}>
                                        {loading ? 'Saqlanmoqda...' : 'Saqlash'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal>
            )}
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose} // Update the message here
                autoHideDuration={3000} // Automatically close after 3 seconds
            >
                <MuiAlert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Ma'lumotlar muvaffaqiyatli saqlandi!
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default ContentTable;




// https://api.jismoniy-tayyorgarlik.uz/9c22468b26fa70722c3ba99a870eb3bc8cbf1315aeb2b0cefed6b731469d0bae