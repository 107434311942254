import React from 'react';
import './modal.scss';
import 'animate.css'
const Modal = ({ show, onClose, children }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="animate__animated animate__fadeIn">
                <button className="close-button" onClick={onClose}>
                    <i class='bx bx-x'></i>
                </button>
                {children}
            </div>
        </div>
    );
};

export default Modal;