import React from 'react';
import './Sidebar.scss';
import { NavLink } from 'react-router-dom';
import logo from '../../auth/logo.png'
function Sidebar({ sidebarOpen }) {

    return (
        <section id="sidebar" className={
            !sidebarOpen ?
                ''
                :
                'hide'
        }>
            <NavLink to={'/'} className="brand">
                <img src={logo} className='logo' />
                <span className="text-gradient">Dashboard</span>
            </NavLink>
            <ul className="side-menu top">
                <NavLink to={'/'}>
                    <li>
                        <label>
                            <i className='bx bxs-dashboard'></i>
                            <span className="text">Monitoring</span>
                        </label>
                    </li>
                </NavLink>
                <NavLink to={'/special-group'}>
                    <li>
                        <label>
                            <i className='bx bx-user' ></i>
                            <span className="text">Maxsus Bo'linma</span>
                        </label>
                    </li>
                </NavLink>
                <NavLink to={'/ordinary-group'}>
                    <li>
                        <label>
                            <i className='bx bxs-user'></i>
                            <span className="text">Umum qoshin Bo'linma</span>
                        </label>
                    </li>
                </NavLink>
            </ul>
            <ul className="side-menu">
                <span></span>
            </ul>
        </section>
    );
}

export default Sidebar;
