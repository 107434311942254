import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import './MainContent.scss';
import logo from '../../auth/logo.png';
import 'animate.css';
import DownModal from './DownModal/DownModal';
import nodata from '../../auth/nodata.png'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { SpinnerCircular } from 'spinners-react';
function MainContent({ user }) {
    const [data, setData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedGroupType, setSelectedGroupType] = useState('');
    const [selectedAgeGroup, setSelectedAgeGroup] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [showFilterSettings, setShowFilterSettings] = useState(false);
    const [loading, setLoading] = useState(true);

    const monthNames = {
        "01": "Yanvar", "02": "Fevral", "03": "Mart", "04": "Aprel", "05": "May",
        "06": "Iyun", "07": "Iyul", "08": "Avgust", "09": "Sentyabr", "10": "Oktabr", "11": "Noyabr", "12": "Dekabr"
    };

    const parseMonth = useCallback((dateString) => {
        if (!dateString) return null;
        const [day, month] = dateString.split(' ');
        const months = {
            'January': '01', 'February': '02', 'March': '03', 'April': '04', 'May': '05',
            'June': '06', 'July': '07', 'August': '08', 'September': '09', 'October': '10',
            'November': '11', 'December': '12'
        };
        return months[month] || null;
    }, []);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get('https://api.jismoniy-tayyorgarlik.uz/b9462645f7363a00c427b9fa9276776dc259cd8f2158723eb5bf5928a1c783d0/', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const combinedData = [...response.data.specialGroups, ...response.data.ordinaryGroups];
            setData(combinedData);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    }, []);


    const filteredData = useMemo(() => {
        return data.filter((item) => {
            const itemMonth = parseMonth(item.createdAt);
            const matchesMonth = selectedMonth ? itemMonth === selectedMonth : true;
            const matchesGroupType = selectedGroupType ? item.groupType === selectedGroupType : true;
            const matchesAgeGroup = selectedAgeGroup ? item.ageGroup === selectedAgeGroup : true;
            return matchesMonth && matchesGroupType && matchesAgeGroup;
        });
    }, [data, selectedMonth, selectedGroupType, selectedAgeGroup, parseMonth]);

    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        return filteredData.slice(startIndex, startIndex + itemsPerPage);
    }, [currentPage, filteredData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const maxsusCount = filteredData.filter(item => item.groupType === "Maxsus bo'linma").length;
    const oddiyCount = filteredData.filter(item => item.groupType === "Umum qo'shin bo'linma").length;
    const totalCount = filteredData.length;

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const toggleFilterSettings = () => {
        setShowFilterSettings(!showFilterSettings);
    };

    const gradeStats = useMemo(() => {
        const total = filteredData.length;
        const grades = filteredData.reduce((acc, item) => {
            acc[item.grade] = (acc[item.grade] || 0) + 1;
            return acc;
        }, {});

        return {
            two: (grades['2'] || 0) / total * 100,
            three: (grades['3'] || 0) / total * 100,
            four: (grades['4'] || 0) / total * 100,
            five: (grades['5'] || 0) / total * 100,
        };
    }, [filteredData]);


    return (
        <main className='main-container animate__animated animate__fadeIn'>
            <div className="main-header">
                <h1>Monitoring</h1>
            </div>

            <div className="main-content">
                <div className='flex'>
                    <div className="month-selector">
                        <p>Ma'lumotlarni oy bo'yicha saralash</p>
                        <select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                            <option value="">Barchasi</option>
                            <option value="01">Yanvar</option>
                            <option value="02">Fevral</option>
                            <option value="03">Mart</option>
                            <option value="04">Aprel</option>
                            <option value="05">May</option>
                            <option value="06">Iyun</option>
                            <option value="07">Iyul</option>
                            <option value="08">Avgust</option>
                            <option value="09">Sentyabr</option>
                            <option value="10">Oktabr</option>
                            <option value="11">Noyabr</option>
                            <option value="12">Dekabr</option>
                        </select>
                    </div>

                    <div className="main-stat">
                        <div className="stat-item">
                            <h2>{maxsusCount}</h2>
                            <p>Maxsus Bo'linma va Kursantlar</p>
                        </div>

                        <div className="stat-item">
                            <h2>{oddiyCount}</h2>
                            <p>Umum Qo'shin Bo'linma</p>
                        </div>

                        <div className="stat-item">
                            <h2>{totalCount}</h2>
                            <p>Jami</p>
                        </div>
                    </div>
                </div>
                <div className="main-user">
                    <div className="user-info">
                        <img className='animate__animated animate__flipInY' src={logo} alt="User" />
                        <div>
                            <h3>{user.fullName}</h3>
                            <p>Xush Kelsibsiz !</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="progress-bars">

                <div className="title">
                    <h1>Ma'lumotlar foizda</h1>
                    <p>Ma'lumotlar soni {filteredData.length}</p>
                </div>

                <div className="progress">


                    {loading ? (
                        <div className="progress-loader">
                            <SpinnerCircular size={50} thickness={100} speed={100} color="rgba(57, 172, 63, 1)" />
                        </div>
                    ) : (
                        <>
                            <div className="progress-item">
                                <h4>2 Baho</h4>
                                <CircularProgressbar
                                    value={gradeStats.two}
                                    text={`${Math.round(gradeStats.two)}%`}
                                    styles={buildStyles({ textSize: '16px', pathColor: '#f00', textColor: '#f00' })}
                                />
                            </div>
                            <div className="progress-item">
                                <h4>3 Baho</h4>
                                <CircularProgressbar
                                    value={gradeStats.three}
                                    text={`${Math.round(gradeStats.three)}%`}
                                    styles={buildStyles({ textSize: '16px', pathColor: '#ffa500', textColor: '#ffa500' })}
                                />
                            </div>
                            <div className="progress-item">
                                <h4>4 Baho</h4>
                                <CircularProgressbar
                                    value={gradeStats.four}
                                    text={`${Math.round(gradeStats.four)}%`}
                                    styles={buildStyles({ textSize: '16px', pathColor: '#00f', textColor: '#00f' })}
                                />
                            </div>
                            <div className="progress-item">
                                <h4>5 Baho</h4>
                                <CircularProgressbar
                                    value={gradeStats.five}
                                    text={`${Math.round(gradeStats.five)}%`}
                                    styles={buildStyles({ textSize: '16px', pathColor: '#0f0', textColor: '#0f0' })}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className="tableActions" style={{ position: 'relative' }}>
                <h3>Ma'lumotlar jadvali</h3>
                <div className='download'>
                    <button className="fillter" onClick={toggleFilterSettings}>
                        <i className='bx bx-filter-alt'></i>
                        Saralash
                    </button>
                    <button onClick={handleOpen} className="excel">
                        <i className='bx bx-download'></i>
                        Yuklab olish
                    </button>
                </div>

                {showFilterSettings && (
                    <div className="filter-settings">
                        <h3>Saralash Sozlamalari</h3>
                        <p>Bo'linma turi bo'yicha saralash:</p>
                        <label>
                            <input
                                type="radio"
                                name="groupType"
                                value=""
                                checked={selectedGroupType === ""}
                                onChange={() => setSelectedGroupType('')}
                            /> Barchasi
                        </label>
                        <br />
                        <label>
                            <input
                                type="radio"
                                name='groupType'
                                value="Maxsus bo'linma"
                                checked={selectedGroupType === "Maxsus bo'linma"}
                                onChange={(e) => setSelectedGroupType(e.target.value)}
                            /> Maxsus Bo'linma <br />
                        </label>
                        <label>
                            <input
                                type="radio"
                                name='groupType'
                                value="Umum qo'shin bo'linma"
                                checked={selectedGroupType === "Umum qo'shin bo'linma"}
                                onChange={(e) => setSelectedGroupType(e.target.value)}
                            /> Umum qo'shin bo'linma
                        </label>
                        <label>
                            <p>Yosh Guruhi bo'yicha saralash:</p>
                            <select value={selectedAgeGroup} onChange={(e) => setSelectedAgeGroup(e.target.value)}>
                                <option value="">Barchasi</option>
                                <option value="I">I</option>
                                <option value="II">II</option>
                                <option value="III">III</option>
                                <option value="IV">IV</option>
                                <option value="V">V</option>
                                <option value="VI">VI</option>
                                <option value="VII">VII</option>
                            </select>
                        </label>
                        <br />
                        <button onClick={toggleFilterSettings}>Yopish</button>
                    </div>
                )}
            </div>

            <div className="main-table">
                <table className="content-table">
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>F.I.Sh</th>
                            <th >Yosh Guruh</th>
                            <th>Bo'linma Turi</th>
                            <th>Baho</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.length === 0 ? (
                            <tr style={{ textAlign: 'center', width: '100%' }}>
                                <img className='nodata' src={nodata} alt="" />
                                <p className='nodata-text'>
                                    Ma'lumot topilmadi
                                </p>
                            </tr>
                        ) : (
                            paginatedData.map((item, index) => (
                                <tr key={index}>
                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                    <td>{item.fullName}</td>
                                    <td>{item.ageGroup}</td>
                                    <td>{item.groupType}</td>
                                    <td>{item.grade}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
                {totalPages > 1 && (
                    <div className="pagination">
                        {Array.from({ length: totalPages }, (_, i) => (
                            <button
                                key={i}
                                onClick={() => handlePageChange(i + 1)}
                                className={currentPage === i + 1 ? 'active' : ''}
                            >
                                {i + 1}
                            </button>
                        ))}
                    </div>
                )}
            </div>
            <DownModal handleClose={handleClose} open={open} filteredData={filteredData} />
        </main>
    );
}

export default MainContent;
