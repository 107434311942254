import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.scss';
import 'animate.css'
function Navbar({ user, handleOpen, toggleDarkMode, handleLogout }) {
    return (
        <nav className='animate__animated animate__fadeInDown'>
            <div>
                <i className='bx bx-menu' onClick={handleOpen}></i>
            </div>

            <div className='profile-tools'>
                <label htmlFor="switch-mode" className="switch-mode-label">
                    <span className="switch-mode-text">
                        <i className='moon bx bxs-sun' ></i>
                    </span>
                </label>
                <input onClick={toggleDarkMode} type="checkbox" id="switch-mode" hidden />
                <label htmlFor="switch-mode" className="switch-mode"></label>
                <NavLink className="notification">
                    <i className='sun bx bxs-moon'></i>
                </NavLink>
            </div>

            <div className='user-details'>
                <i className='bx bxs-user-circle'></i>
                <span className="username">{user ? user.username : 'User Name'}</span>
                <button className='logout' onClick={handleLogout}>
                    <i className='bx bx-log-out-circle' ></i>
                    Chiqish
                </button>
            </div>
        </nav>
    );
}

export default Navbar;
