import React, { useState } from 'react';
import './special.scss';
import ContentTable from './Table/Table';
import 'animate.css'
const SpecialTable = () => {
    const [showModal, setShowModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <div className='container-table'>
            <div className="table-header animate__animated animate__fadeIn">
                <div className="header-text">
                    <h1>Maxsus Bo’linmalar Va Kursantlar Uchun</h1>
                </div>
                <div className="header-search">
                    <input
                        type="text"
                        placeholder="Qidirish..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <button className="search-button">
                        <i className='bx bx-search'></i>
                    </button>
                </div>

                <div className="header-buttons">
                    <button className="add-button" onClick={() => setShowModal(true)}>
                        <i className='bx bx-plus'></i>
                        Ma'lumot qo'shish
                    </button>
                </div>
            </div>

            <div className="table">
                <ContentTable showModal={showModal} setShowModal={setShowModal} searchQuery={searchQuery} />
            </div>
        </div>
    );
};

export default SpecialTable;
