import React, { useState } from 'react';
import logo from './logo.png';
import './login.scss';

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Added loading state

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading
        try {
            const response = await fetch('https://api.jismoniy-tayyorgarlik.uz/287f471fb2a3f42aef383cef4a32be2731dc312254a86f166586c32dc6f80b55/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password })
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('token', data.token); // Save token in localStorage
                onLogin(data.token, data.user); // Call the login function
            } else {
                const errorData = await response.json();
                setError(errorData.message);
            }
        } catch (err) {
            setError('Xatolik yuz berdi. Qaytadan urinib ko`ring');
        } finally {
            setLoading(false); // Stop loading after request is finished
        }
    };

    return (
        <div className='loginContainer'>
            <form className='loginForm' onSubmit={handleSubmit}>
                <img src={logo} alt="" />
                <h1 style={{ textAlign: 'center' }}>Tizimga Kirish</h1>
                <div className='login'>
                    <i className='bx bx-id-card'></i>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Login"
                        required
                    />
                </div>
                <div className='parol'>
                    <i className='bx bx-key'></i>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Parol"
                        required
                    />
                </div>
                <button type="submit" disabled={loading}>
                    {loading ? 'Yuklanmoqda...' : 'Oldinga'}
                </button>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </form>
        </div>
    );
};

export default Login;
