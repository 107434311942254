import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Import a library to decode JWT tokens
import Sidebar from './components/Sidebar/Sidebar';
import Navbar from './components/Navbar/Navbar';
import MainContent from './components/MainContent/MainContent';
import SpecialTable from './components/SpecialTable/SpecialTable';
import Login from './auth/Login';
import './App.css';
import OrdinaryGroup from './components/OrdinaryGroup/OrdinaryGroup';

function App() {
    const [sidebarOpen, setSidebarOpen] = useState(() => {
        const savedSidebarOpen = localStorage.getItem('sidebarOpen');
        return savedSidebarOpen !== null ? JSON.parse(savedSidebarOpen) : true;
    });

    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedIsDarkMode = localStorage.getItem('isDarkMode');
        return savedIsDarkMode !== null ? JSON.parse(savedIsDarkMode) : false;
    });

    const [token, setToken] = useState(() => {
        const savedToken = localStorage.getItem('token');
        if (savedToken) {
            const decodedToken = jwtDecode(savedToken);
            if (decodedToken.exp * 1000 < Date.now()) { // Token expired
                localStorage.removeItem('token');
                return null;
            }
            return savedToken;
        }
        return null;
    });

    const [user, setUser] = useState(() => {
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });

    useEffect(() => {
        localStorage.setItem('sidebarOpen', JSON.stringify(sidebarOpen));
    }, [sidebarOpen]);

    useEffect(() => {
        localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
        document.body.classList.toggle('dark', isDarkMode);
    }, [isDarkMode]);

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        } else {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
        }
    }, [token]);

    useEffect(() => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            localStorage.removeItem('user');
        }
    }, [user]);

    const handleOpen = () => {
        setSidebarOpen(prevState => !prevState);
    };

    const toggleDarkMode = () => {
        setIsDarkMode(prevState => !prevState);
    };

    const handleLogin = (token, user) => {
        setToken(token);
        setUser(user);
    };

    const handleLogout = () => {
        setToken(null);
        setUser(null);
    };

    return (
        <BrowserRouter>
            <div>
                <div className="marquee-container">
                    <div>
                        <marquee behavior="scroll" direction="left" scrollamount="5">
                            <div className='marquee-content'>
                                <div>
                                    Izoh:
                                    <span>Ushbu sayt test rejimida ishga tushirildi</span>
                                </div>
                                <div>
                                    Izoh:
                                    <span>Ushbu sayt test rejimida ishga tushirildi</span>
                                </div>
                                <div>
                                    Izoh:
                                    <span>Ushbu sayt test rejimida ishga tushirildi</span>
                                </div>
                                <div>
                                    Izoh:
                                    <span>Ushbu sayt test rejimida ishga tushirildi</span>
                                </div>
                                <div>
                                    Izoh:
                                    <span>Ushbu sayt test rejimida ishga tushirildi</span>
                                </div>
                                <div>
                                    Izoh:
                                    <span>Ushbu sayt test rejimida ishga tushirildi</span>
                                </div>
                                <div>
                                    Izoh:
                                    <span>Ushbu sayt test rejimida ishga tushirildi</span>
                                </div>
                                <div>
                                    Izoh:
                                    <span>Ushbu sayt test rejimida ishga tushirildi</span>
                                </div>
                                <div>
                                    Izoh:
                                    <span>Ushbu sayt test rejimida ishga tushirildi</span>
                                </div>
                                <div>
                                    Izoh:
                                    <span>Ushbu sayt test rejimida ishga tushirildi</span>
                                </div>
                                <div>
                                    Izoh:
                                    <span>Ushbu sayt test rejimida ishga tushirildi</span>
                                </div>
                                <div>
                                    Izoh:
                                    <span>Ushbu sayt test rejimida ishga tushirildi</span>
                                </div>
                                <div>
                                    Izoh:
                                    <span>Ushbu sayt test rejimida ishga tushirildi</span>
                                </div>
                                <div>
                                    Izoh:
                                    <span>Ushbu sayt test rejimida ishga tushirildi</span>
                                </div>
                            </div>
                        </marquee>
                    </div>
                </div>
                {token && <Sidebar sidebarOpen={sidebarOpen} />}
                <div id="content">
                    {token && <Navbar user={user} toggleDarkMode={toggleDarkMode} handleOpen={handleOpen} handleLogout={handleLogout} />}
                    <Routes>
                        <Route path="/login" element={token ? <Navigate to="/" /> : <Login onLogin={handleLogin} />} />
                        <Route path="/" element={token ? <MainContent user={user} /> : <Navigate to="/login" />} />
                        <Route path="/special-group" element={token ? <SpecialTable /> : <Navigate to="/login" />} />
                        <Route path="/ordinary-group" element={token ? <OrdinaryGroup /> : <Navigate to="/login" />} />
                    </Routes>
                </div>
            </div>
        </BrowserRouter >
    );
}

export default App;
