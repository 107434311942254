import React from 'react';
import './downModal.scss';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import docx from 'html-docx-js/dist/html-docx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas'
const DownModal = ({ open, handleClose, filteredData }) => {

    const style = {
        position: 'absolute',
        top: '0%',
        left: '25%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        bgcolor: 'var(--light)',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
        height: 800,
    };

    const allExercises = ['2-Mashq', '4-Mashq', '5b-Mashq', '9-Mashq'];

    const processFilteredData = (data) => {
        return data.map(item => ({
            fullName: item.fullName || "N/A",
            ageGroup: item.ageGroup || "N/A",
            totalScore: item.score || "N/A",
            grade: item.grade || "N/A",
            exercises: allExercises.map(exerciseName => {
                const exercise = item.exercises.find(ex => ex.exerciseName === exerciseName);
                return {
                    exerciseName,
                    result: exercise ? exercise.result : "0",
                    score: exercise ? exercise.score : "0"
                };
            })
        }));
    };

    const processedData = processFilteredData(filteredData);

    const exportToPDF = () => {
        const input = document.getElementById('tableToExport');

        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('landscape', 'pt', 'a4');

            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();

            // Kichikroq o'lchamdagi tasvirni belgilash
            const imgWidth = pageWidth * 0.8;  // Kenglikni 80% ga kamaytirish
            const imgHeight = canvas.height * imgWidth / canvas.width;

            // Tasvirni PDF markaziga joylashtirish
            const xPos = (pageWidth - imgWidth) / 2;
            const yPos = (pageHeight - imgHeight) / 2;

            pdf.addImage(imgData, 'PNG', xPos, yPos, imgWidth, imgHeight);
            pdf.save('table.pdf');
        });
    };





    const exportToWord = () => {
        const table = document.getElementById('tableToExport');
        if (!table) {
            console.error("Table element not found.");
            return;
        }

        const styledTableHTML = table.outerHTML.replace(/<td/g, '<td style="border: 1px solid black; padding: 5px;"')
            .replace(/<th/g, '<th style="border: 1px solid black; padding: 5px;"')
            .replace(/<table/g, '<table style="border-collapse: collapse;"');

        const converted = docx.asBlob(styledTableHTML);
        saveAs(converted, 'table.docx');
    };

    const exportToExcel = () => {
        const table = document.getElementById('tableToExport');
        if (!table) {
            console.error("Table element not found.");
            return;
        }
        const ws = XLSX.utils.table_to_sheet(table);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "table.xlsx");
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='animate__animated animate__fadeInDown' sx={style}>
                <div id="tableToExport" style={{ textAlign: 'center', overflow: 'auto', height: 700 }}>
                    <p className="top-space"></p>
                    <p className="s1">
                        20 <u>&nbsp;&nbsp;&nbsp; </u>й. “<u>&nbsp;&nbsp;&nbsp;</u>”
                        <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </u>куни
                        <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </u>нинг
                        жисмоний
                    </p>
                    <p className="s3"> (бўлинма номи)</p>
                    <p className="s1">тайёргарлик даражасини текшириш бўйича ўтказилган тадбирлар натижасининг</p>
                    <h1>В Е Д О М О С Т И</h1>
                    <br />
                    <table className="data-table" cellSpacing="0">
                        <thead>
                            <tr style={{ height: '29pt' }}>
                                <td className="td-default" rowSpan="2">
                                    <p className="s4">т.р.</p>
                                </td>
                                <td className="td-default" rowSpan="2">
                                    <p className="s4">Ф.И.Ш.</p>
                                </td>
                                <td className="td-default" rowSpan="2">
                                    <p className="s4">Ёш гуруҳи</p>
                                </td>
                                {allExercises.map((exercise, index) => (
                                    <React.Fragment key={index}>
                                        <td className="td-default" colSpan="2">
                                            <p className="s4">{exercise}</p>
                                        </td>
                                    </React.Fragment>
                                ))}
                                <td className="td-default" colSpan="2">
                                    <p className="s4">Умумий балл ва баҳо</p>
                                </td>
                            </tr>
                            <tr style={{ height: '26pt' }}>
                                {allExercises.map((_, index) => (
                                    <React.Fragment key={index}>
                                        <td className="td-default">
                                            <p className="s4">натижа</p>
                                        </td>
                                        <td className="td-default">
                                            <p className="s4">балл</p>
                                        </td>
                                    </React.Fragment>
                                ))}
                                <td className="td-default">
                                    <p className="s4">балл</p>
                                </td>
                                <td className="td-default">
                                    <p className="s4">баҳо</p>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {processedData.length === 0 ? (
                                <tr>
                                    <td colSpan={allExercises.length * 2 + 3} style={{ textAlign: 'center' }}>Маълумот топилмади</td>
                                </tr>
                            ) : (
                                processedData.map((item, index) => (
                                    <tr key={index} style={{ height: '18pt' }}>
                                        <td className="td-default">
                                            <p className="s4">{index + 1}</p>
                                        </td>
                                        <td className="td-default">
                                            <p className="s5">{item.fullName}</p>
                                        </td>
                                        <td className="td-default">
                                            <p className="s4">{item.ageGroup}</p>
                                        </td>
                                        {item.exercises.map((exercise, i) => (
                                            <React.Fragment key={i}>
                                                <td className="td-default">
                                                    <p className="s5">{exercise.result}</p>
                                                </td>
                                                <td className="td-default">
                                                    <p className="s5">{exercise.score}</p>
                                                </td>
                                            </React.Fragment>
                                        ))}
                                        <td className="td-default">
                                            <p className="s5">{item.totalScore}</p>
                                        </td>
                                        <td className="td-default">
                                            <p className="s5">{item.grade}</p>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="modal-actions">
                    <button className='exit' onClick={handleClose}>
                        <i class='bx bx-arrow-back'></i>
                        Chiqish
                    </button>
                    <div className='modal-btns'>
                        <button className='pdf' onClick={exportToPDF}>
                            <i class='bx bx-download' ></i>
                            PDF
                        </button>
                        <button className='word' onClick={exportToWord}>
                            <i class='bx bx-download' ></i>
                            WORD
                        </button>
                        <button className='excel' onClick={exportToExcel}>
                            <i class='bx bx-download' ></i>
                            Excel
                        </button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}

export default DownModal;
