export const calculateScore = (exerciseName, result, ageGroup) => {
    const scoreMap = {
        '2-Mashq': {
            'I': {
                '15.4': 1, '15.3': 2, '15.2': 3, '15.1': 4, '15.0': 5, '14.9': 6, '14.8': 7, '14.7': 8, '14.6': 9, '14.5': 10,
                '14.4': 11, '14.3': 12, '14.2': 13, '14.1': 14, '14.0': 15, '13.9': 16, '13.8': 17, '13.7': 18, '13.6': 19, '13.5': 20,
                '13.4': 21, '13.3': 22, '13.2': 23, '13.1': 24, '13.0': 25, '12.9': 25, '12.8': 25, '12.7': 25, '12.6': 25, '12.5': 25, '12.4': 25,
                '12.3': 25, '12.2': 25, '12.1': 25, '12.0': 25
            },
            'II': {
                '15.6': 1, '15.5': 2, '15.4': 3, '15.3': 4, '15.2': 5, '15.1': 6, '15.0': 7, '14.9': 8, '14.8': 9, '14.7': 10, '14.6': 11, '14.5': 12,
                '14.4': 13, '14.3': 14, '14.2': 15, '14.1': 16, '14.0': 17, '13.9': 18, '13.8': 19, '13.7': 20, '13.6': 21, '13.5': 22,
                '13.4': 23, '13.3': 24, '13.2': 25, '13.1': 26, '13.0': 27
            },
            'III': {
                '16.2': 1, '16.1': 2, '16.0': 3, '15.9': 4, '15.8': 5, '15.7': 6, '15.6': 7, '15.5': 8, '15.4': 9, '15.3': 10,
                '15.2': 11, '15.1': 12, '15.0': 13, '14.9': 14, '14.8': 15, '14.7': 16, '14.6': 17, '14.5': 18, '14.4': 19, '14.3': 20,
                '14.2': 21, '14.1': 22, '14.0': 23, '13.9': 24, '13.8': 25, '13.7': 26, '13.6': 27, '13.5': 29, '13.4': 30,
                '13.3': 31, '13.2': 32, '13.1': 33, '13.0': 34
            },
            'IV': {
                '16.8': 1, '16.7': 2, '16.6': 3, '16.5': 4, '16.4': 5, '16.3': 6, '16.2': 7, '16.1': 8, '16.0': 9, '15.9': 10,
                '15.8': 11, '15.7': 12, '15.6': 13, '15.5': 14, '15.4': 15, '15.3': 16, '15.2': 17, '15.1': 18, '15.0': 19, '14.9': 20,
                '14.8': 21, '14.7': 22, '14.6': 23, '14.5': 24, '14.4': 25, '14.3': 26, '14.2': 27, '14.1': 29, '14.0': 30,
                '14.1': 31, '14.2': 32, '14.1': 33, '14.0': 34
            },
            'V': {
                '16.8': 1, '16.7': 2, '16.6': 3, '16.5': 4, '16.4': 5, '16.3': 6, '16.2': 7, '16.1': 8, '16.0': 9, '15.9': 10,
                '15.8': 11, '15.7': 12, '15.6': 13, '15.5': 14, '15.4': 15, '15.3': 16, '15.2': 17, '15.1': 18, '15.0': 19, '14.9': 20,
                '14.8': 21, '14.7': 22, '14.6': 23, '14.5': 24, '14.4': 25, '14.3': 26, '14.2': 27, '14.1': 29, '14.0': 30, '14.1': 31, '14.2': 32,
                '14.1': 33, '14.0': 34
            }
        },
        '4-Mashq': {
            'I': {
                '4.14': 1, '4.13': 1, '4.12': 1, '4.11': 1, '4.10': 2, '4.09': 2, '4.08': 2, '4.07': 2, '4.06': 3, '4.05': 3, '4.04': 3, '4.03': 3, '4.02': 4, '4.01': 4, '4.00': 4, '3.59': 4,
                '3.58': 5, '3.57': 5, '3.56': 5, '3.55': 5, '3.54': 6, '3.53': 6, '3.52': 6, '3.51': 6, '3.50': 7, '3.49': 7, '3.48': 7, '3.47': 7, '3.46': 8, '3.45': 8, '3.44': 8, '3.43': 9, '3.40': 10,
                '3.38': 11, '3.37': 11, '3.36': 12, '3.35': 12, '3.34': 13, '3.33': 13, '3.32': 14, '3.31': 14, '3.30': 15, '3.29': 15, '3.28': 16, '3.27': 16, '3.26': 17, '3.25': 17, '3.24': 18, '3.23': 18,
                '3.22': 19, '3.21': 19, '3.20': 20, '3.19': 20, '3.18': 21, '3.17': 21, '3.16': 22, '3.15': 22, '3.14': 23, '3.13': 23, '3.12': 24, '3.11': 24, '3.10': 25
            },
            'II': {
                '4.20': 1, '4.19': 1, '4.18': 1, '4.17': 2, '4.14': 3, '4.13': 3, '4.12': 3, '4.11': 3, '4.10': 4, '4.10': 4, '4.09': 4,
                '4.08': 4, '4.07': 5, '4.06': 5, '4.05': 5, '4.04': 6, '4.03': 6, '4.02': 6, '4.01': 7, '4.00': 7, '3.59': 7, '3.58': 8,
                '3.57': 8, '3.56': 8, '3.55': 9, '3.54': 9, '3.53': 9, '3.52': 10, '3.51': 10, '3.50': 10, '3.49': 11, '3.48': 11, '3.47': 11,
                '3.46': 12, '3.45': 12, '3.44': 12, '3.43': 13, '3.42': 13, '3.41': 13, '3.40': 14, '3.39': 14, '3.38': 15, '3.37': 15, '3.36': 16,
                '3.35': 16, '3.34': 17, '3.33': 17, '3.32': 18, '3.31': 19, '3.30': 19, '3.29': 19, '3.28': 20, '3.27': 20, '3.26': 21, '3.25': 21, '3.24': 22,
                '3.23': 22, '3.22': 23, '3.21': 23, '3.20': 24, '3.19': 24, '3.18': 25
            },
            'III': {
                '4.50': 1, '4.49': 1, '4.48': 1, '4.47': 1, '4.46': 2, '4.45': 2, '4.44': 2, '4.43': 2, '4.42': 3, '4.41': 3, '4.40': 3,
                '4.38': 4, '4.37': 4, '4.36': 4, '4.35': 4, '4.34': 5, '4.33': 5, '4.32': 5, '4.31': 5, '4.30': 6, '4.29': 6, '4.28': 7,
                '4.27': 7, '4.26': 7, '4.25': 7, '4.24': 8, '4.23': 8, '4.22': 8, '4.21': 9, '4.20': 9, '4.19': 9, '4.18': 10, '4.17': 10, '4.16': 10,
                '4.15': 11, '4.14': 11, '4.13': 11, '4.12': 12, '4.11': 12, '4.10': 13, '4.09': 13, '4.08': 14, '4.07': 14, '4.06': 14, '4.05': 15,
                '4.04': 15, '4.03': 15, '4.02': 16, '4.01': 16, '4.00': 16, '3.59': 17, '3.58': 18, '3.57': 18, '3.56': 18, '3.55': 18, '3.54': 18, '3.53': 19,
                '3.52': 19, '3.51': 19, '3.50': 20, '3.49': 20, '3.48': 21, '3.47': 21, '3.46': 22, '3.45': 22, '3.44': 23, '3.43': 23, '3.42': 24, '3.41': 24, '3.40': 25
            },
            'IV': {
                '5.14': 1, '5.13': 1, '5.12': 1, '5.11': 1, '5.10': 2, '5.09': 2, '5.08': 2, '5.07': 2, '5.06': 3, '5.05': 3, '5.04': 3,
                '5.03': 3, '5.02': 4, '5.01': 4, '5.00': 4, '4.59': 4, '4.58': 5, '4.57': 5, '4.56': 5, '4.55': 5, '4.54': 5, '4.55': 5,
                '4.53': 5, '4.52': 6, '4.51': 6, '4.50': 6, '4.49': 6, '4.48': 7, '4.47': 7, '4.46': 7, '4.45': 7, '4.44': 8, '4.43': 8,
                '4.42': 8, '4.41': 8, '4.40': 9, '4.39': 9, '4.38': 9, '4.37': 9, '4.36': 10, '4.35': 10, '4.34': 10, '4.33': 10, '4.32': 11,
                '4.31': 11, '4.30': 11, '4.29': 11, '4.28': 12, '4.27': 12, '4.26': 12, '4.25': 12, '4.24': 13, '4.23': 13, '4.22': 13,
                '4.21': 13, '4.20': 14, '4.19': 14, '4.18': 14, '4.17': 14, '4.16': 15, '4.15': 15, '4.14': 15, '4.13': 15, '4.12': 16,
                '4.11': 16, '4.10': 17, '4.09': 17, '4.08': 18, '4.07': 18, '4.06': 19, '4.05': 19, '4.04': 20, '4.03': 20, '4.02': 21,
                '4.01': 21, '4.00': 22, '3.59': 22, '3.58': 23, '3.57': 23, '3.56': 24, '3.55': 24, '3.54': 25, '3.53': 26, '3.52': 27
            },
            'V': {
                '5.36': 1, '5.35': 1, '5.34': 1, '5.33': 1, '5.32': 2, '5.31': 2, '5.30': 2, '5.29': 2, '5.28': 3, '5.27': 3, '5.26': 3,
                '5.25': 3, '5.24': 4, '5.23': 4, '5.22': 4, '5.21': 4, '5.20': 5, '5.19': 5, '5.18': 5, '5.17': 5, '5.16': 6, '5.15': 6,
                '5.14': 6, '5.13': 6, '5.12': 7, '5.11': 7, '5.10': 7, '5.09': 7, '5.08': 8, '5.07': 8, '5.06': 8, '5.05': 8, '5.04': 9,
                '5.03': 9, '5.02': 9, '5.01': 9, '5.00': 10, '4.59': 10, '4.58': 10, '4.57': 10, '4.56': 11, '4.55': 11, '4.54': 11, '4.53': 11,
                '4.52': 12, '4.51': 12, '4.50': 12, '4.49': 12, '4.48': 13, '4.47': 13, '4.46': 13, '4.45': 14, '4.44': 14, '4.43': 14, '4.42': 15,
                '4.41': 15, '4.40': 15, '4.39': 16, '4.38': 16, '4.37': 16, '4.36': 17, '4.35': 17, '4.34': 17, '4.33': 18, '4.32': 18, '4.31': 18,
                '4.30': 19, '4.29': 19, '4.28': 19, '4.27': 20, '4.26': 20, '4.25': 20, '4.24': 21, '4.23': 21, '4.22': 21, '4.21': 22, '4.20': 22,
                '4.19': 22, '4.18': 23, '4.17': 23, '4.16': 23, '4.15': 24, '4.14': 24, '4.13': 24, '4.12': 25,
            }
        },
        '5b-Mashq': {
            'I': {
                '13.50': 1, '13.49': 1, '13.48': 1, '13.47': 1, '13.46': 1, '13.45': 2, '13.44': 2, '13.43': 2, '13.42': 2, '13.41': 2,
                '13.40': 3, '13.39': 3, '13.38': 3, '13.37': 3, '13.36': 3, '13.35': 4, '13.34': 4, '13.33': 4, '13.32': 4, '13.31': 4,
                '13.30': 5, '13.29': 5, '13.28': 5, '13.27': 5, '13.26': 5, '13.25': 6, '13.24': 6, '13.23': 6, '13.22': 6, '13.21': 6,
                '13.20': 7, '13.19': 7, '13.18': 7, '13.17': 7, '13.16': 7, '13.15': 8, '13.14': 8, '13.13': 8, '13.12': 8, '13.11': 8,
                '13.10': 9, '13.09': 9, '13.08': 9, '13.07': 9, '13.06': 9, '13.05': 10, '13.04': 10, '13.03': 10, '13.02': 10, '13.01': 10,
                '13.00': 11, '12.59': 11, '12.58': 11, '12.57': 11, '12.56': 11, '12.55': 12, '12.54': 12, '12.53': 12, '12.51': 12, '12.50': 13,
                '12.49': 13, '12.48': 13, '12.47': 13, '12.46': 13, '12.45': 14, '12.44': 14, '12.43': 14, '12.42': 14, '12.41': 14, '12.40': 15,
                '12.39': 15, '12.38': 15, '12.37': 15, '12.36': 15, '12.35': 16, '12.34': 16, '12.33': 16, '12.32': 16, '12.31': 16, '12.30': 17,
                '12.29': 17, '12.28': 17, '12.27': 17, '12.26': 17, '12.25': 17, '12.24': 17, '12.23': 17, '12.22': 17, '12.21': 17, '12.20': 18,
                '12.19': 18, '12.18': 18, '12.17': 18, '12.16': 18, '12.15': 18, '12.14': 18, '12.13': 18, '12.12': 18, '12.11': 18, '12.10': 19,
                '12.09': 19, '12.08': 19, '12.07': 19, '12.06': 19, '12.05': 19, '12.04': 19, '12.03': 19, '12.02': 19, '12.01': 19, '12.00': 20,
                '11.59': 20, '11.58': 20, '11.57': 20, '11.56': 20, '11.55': 20, '11.54': 20, '11.53': 20, '11.52': 20, '11.51': 20, '11.50': 21,
                '11.49': 21, '11.48': 21, '11.47': 21, '11.46': 21, '11.45': 21, '11.44': 21, '11.43': 21, '11.42': 21, '11.41': 21, '11.40': 22,
                '11.39': 22, '11.38': 22, '11.37': 22, '11.36': 22, '11.35': 22, '11.34': 22, '11.33': 22, '11.32': 22, '11.31': 22, '11.30': 23,
                '11.29': 23, '11.28': 23, '11.27': 23, '11.26': 23, '11.25': 23, '11.24': 23, '11.23': 23, '11.22': 23, '11.21': 23, '11.20': 24,
                '11.19': 24, '11.18': 24, '11.17': 24, '11.16': 24, '11.15': 24, '11.14': 24, '11.13': 24, '11.12': 24, '11.11': 24, '11.10': 25,
            },
            'II': {
                '14.10': 1, '14.09': 1, '14.08': 1, '14.07': 1, '14.06': 1, '14.05': 1, '14.04': 1, '14.03': 1, '14.02': 1, '14.01': 1,
                '14.00': 2, '13.59': 2, '13.58': 2, '13.57': 2, '13.56': 2, '13.55': 3, '13.54': 3, '13.53': 3, '13.52': 3, '13.51': 3,
                '13.50': 4, '13.49': 4, '13.48': 4, '13.47': 4, '13.46': 4, '13.45': 5, '13.44': 5, '13.43': 5, '13.42': 5, '13.41': 5,
                '13.40': 6, '13.39': 6, '13.38': 6, '13.37': 6, '13.36': 6, '13.35': 7, '13.34': 7, '13.33': 7, '13.32': 7, '13.31': 7,
                '13.30': 8, '13.29': 8, '13.28': 8, '13.27': 8, '13.26': 8, '13.25': 9, '13.24': 9, '13.23': 9, '13.22': 9, '13.21': 9,
                '13.20': 10, '13.19': 10, '13.18': 10, '13.17': 10, '13.16': 10, '13.15': 11, '13.14': 11, '13.13': 11, '13.12': 11, '13.11': 11,
                '13.10': 12, '13.09': 12, '13.08': 12, '13.07': 12, '13.06': 12, '13.05': 13, '13.04': 13, '13.03': 13, '13.02': 13, '13.01': 13,
                '13.00': 14, '12.59': 14, '12.58': 14, '12.59': 14, '12.58': 14, '12.57': 14, '12.56': 14, '12.55': 15, '12.54': 15, '12.53': 15,
                '12.52': 15, '12.51': 15, '12.50': 16, '12.49': 16, '12.48': 16, '12.47': 16, '12.46': 16, '12.45': 17, '12.44': 17, '12.43': 17,
                '12.42': 17, '12.41': 17, '12.40': 18, '12.39': 18, '12.38': 18, '12.37': 18, '12.36': 18, '12.35': 18, '12.34': 18, '12.33': 18,
                '12.32': 18, '12.31': 18, '12.30': 19, '12.29': 19, '12.28': 19, '12.27': 19, '12.26': 19, '12.27': 19, '12.28': 19, '12.27': 19,
                '12.26': 19, '12.25': 19, '12.24': 19, '12.23': 19, '12.22': 19, '12.21': 19, '12.20': 20, '12.19': 20, '12.18': 20, '12.17': 20,
                '12.16': 20, '12.15': 20, '12.14': 20, '12.13': 20, '12.12': 20, '12.11': 20, '12.10': 21, '12.09': 21, '12.08': 21, '12.07': 21,
                '12.06': 21, '12.05': 21, '12.04': 21, '12.03': 21, '12.02': 21, '12.01': 21, '12.00': 22, '11.59': 22, '11.58': 22, '11.57': 22,
                '11.56': 22, '11.55': 22, '11.54': 22, '11.53': 22, '11.52': 22, '11.51': 22, '11.50': 23, '11.49': 23, '11.48': 23, '11.47': 23,
                '11.46': 23, '11.45': 23, '11.44': 23, '11.43': 23, '11.42': 23, '11.41': 23, '11.40': 24, '11.41': 24, '11.40': 24, '11.39': 24,
                '11.38': 24, '11.37': 24, '11.36': 24, '11.35': 24, '11.34': 24, '11.33': 24, '11.32': 24, '11.31': 24, '11.30': 24, '11.29': 24,
                '11.28': 24, '11.27': 24, '11.26': 24, '11.25': 24, '11.24': 24, '11.23': 24, '11.22': 24, '11.21': 24, '11.20': 25
            },
            'III': {
                '15.30': 1, '15.29': 1, '15.28': 1, '15.27': 1, '15.26': 1, '15.25': 2, '15.24': 2, '15.23': 2, '15.22': 2, '15.21': 2, '15.20': 3,
                '15.19': 3, '15.18': 3, '15.17': 3, '15.16': 3, '15.15': 4, '15.14': 4, '15.13': 4, '15.12': 4, '15.11': 4,
                '15.10': 5, '15.09': 5, '15.08': 5, '15.07': 5, '15.06': 5, '15.05': 6, '15.04': 6, '15.03': 6, '15.02': 6, '15.01': 6, '15.00': 7,
                '14.59': 7, '14.58': 7, '14.57': 7, '14.56': 7, '14.55': 8, '14.54': 8, '14.53': 8, '14.52': 8, '14.51': 8, '14.50': 9,
                '14.49': 9, '14.48': 9, '14.47': 9, '14.46': 9, '14.45': 10, '14.44': 10, '14.43': 10, '14.42': 10, '14.41': 10, '14.40': 11,
                '14.39': 11, '14.38': 11, '14.37': 11, '14.36': 11, '14.35': 12, '14.34': 12, '14.33': 12, '14.32': 12, '14.31': 12,
                '14.30': 13, '14.29': 13, '14.28': 13, '14.27': 13, '14.26': 13, '14.25': 14, '14.24': 14, '14.23': 14, '14.22': 14, '14.21': 14,
                '14.20': 15, '14.19': 15, '14.18': 15, '14.17': 15, '14.16': 15, '14.15': 16, '14.14': 16, '14.13': 16, '14.12': 16,
                '14.11': 16, '14.10': 17, '14.09': 17, '14.08': 17, '14.07': 17, '14.06': 17, '14.05': 18, '14.04': 18, '14.03': 18,
                '14.02': 18, '14.01': 18, '14.00': 19, '13.59': 19, '13.58': 19, '13.57': 19, '13.56': 19, '13.55': 20, '13.54': 20,
                '13.53': 20, '13.52': 20, '13.51': 20, '13.50': 21, '13.49': 21, '13.48': 21, '13.47': 21, '13.46': 21, '13.45': 22,
                '13.44': 22, '13.43': 22, '13.42': 22, '13.41': 22, '13.40': 23, '13.39': 23, '13.38': 23, '13.37': 23,
                '13.36': 23, '13.35': 24, '13.34': 24, '13.33': 24, '13.32': 24, '13.31': 24, '13.30': 25
            },
            'IV': {
                '16.40': 1, '16.39': 1, '16.38': 1, '16.37': 1, '16.36': 1, '16.35': 2, '16.34': 2, '16.33': 2, '16.32': 2, '16.31': 2,
                '16.30': 3, '16.29': 3, '16.28': 3, '16.27': 3, '16.26': 3, '16.25': 4, '16.24': 4, '16.23': 4, '16.22': 4, '16.21': 4,
                '16.20': 5, '16.19': 5, '16.18': 5, '16.17': 5, '16.16': 5, '16.15': 6, '16.14': 6, '16.13': 6, '16.12': 6, '16.11': 6,
                '16.10': 7, '16.09': 7, '16.08': 7, '16.07': 7, '16.06': 7, '16.05': 7, '16.04': 7, '16.03': 7, '16.02': 7, '16.01': 7,
                '16.00': 8, '15.59': 8, '15.58': 8, '15.57': 8, '15.56': 8, '15.55': 8, '15.54': 8, '15.53': 8, '15.52': 8, '15.51': 8,
                '15.50': 9, '15.49': 9, '15.48': 9, '15.47': 9, '15.46': 9, '15.45': 9, '15.44': 9, '15.43': 9, '15.42': 9, '15.41': 9,
                '15.40': 10, '15.39': 10, '15.38': 10, '15.37': 10, '15.36': 10, '15.35': 10, '15.34': 10, '15.33': 10, '15.32': 10, '15.31': 10,
                '15.30': 11, '15.29': 11, '15.28': 11, '15.27': 11, '15.26': 11, '15.25': 11, '15.24': 11, '15.23': 11, '15.22': 11, '15.21': 11,
                '15.20': 12, '15.19': 12, '15.18': 12, '15.17': 12, '15.16': 12, '15.15': 12, '15.14': 12, '15.13': 12, '15.12': 12, '15.11': 12,
                '15.10': 13, '15.09': 13, '15.08': 13, '15.07': 13, '15.06': 13, '15.05': 13, '15.04': 13, '15.03': 13, '15.02': 13, '15.01': 13,
                '15.00': 14, '14.59': 14, '14.58': 14, '14.57': 14, '14.56': 14, '14.55': 14, '14.54': 14, '14.53': 14, '14.52': 14, '14.51': 14,
                '14.50': 15, '14.49': 15, '14.48': 15, '14.47': 15, '14.46': 15, '14.45': 16, '14.44': 16, '14.43': 16, '14.42': 16, '14.41': 16,
                '14.40': 17, '14.39': 17, '14.38': 17, '14.37': 17, '14.36': 17, '14.35': 18, '14.34': 18, '14.33': 18, '14.32': 18, '14.31': 18,
                '14.30': 19, '14.29': 19, '14.28': 19, '14.27': 19, '14.26': 19, '14.25': 20, '14.24': 20, '14.23': 20, '14.22': 20, '14.21': 20,
                '14.20': 21, '14.19': 21, '14.18': 21, '14.17': 21, '14.16': 21, '14.15': 22, '14.14': 22, '14.13': 22, '14.12': 22, '14.11': 22,
                '14.10': 23, '14.09': 23, '14.08': 23, '14.07': 23, '14.06': 23, '14.05': 24, '14.04': 24, '14.03': 24, '14.02': 24, '14.01': 24,
                '14.00': 25,
            }
        },
        '6a-Mashq': {
            'I': {
                '24.00': 1, '23.59': 1, '23.58': 1, '23.57': 1, '23.56': 1, '23.55': 1, '23.54': 1, '23.53': 1, '23.52': 1, '23.51': 1,
                '23.50': 2, '23.49': 2, '23.48': 2, '23.47': 2, '23.46': 2, '23.45': 2, '23.44': 2, '23.43': 2, '23.42': 2, '23.41': 2,
                '23.40': 3, '23.39': 3, '23.38': 3, '23.37': 3, '23.36': 3, '23.35': 3, '23.34': 3, '23.33': 3, '23.32': 3, '23.31': 3,
                '23.30': 4, '23.29': 4, '23.28': 4, '23.27': 4, '23.26': 4, '23.25': 4, '23.24': 4, '23.23': 4, '23.22': 4, '23.21': 4,
                '23.20': 5, '23.19': 5, '23.18': 5, '23.17': 5, '23.16': 5, '23.15': 5, '23.14': 5, '23.13': 5, '23.12': 5, '23.11': 5,
                '23.10': 6, '23.09': 6, '23.08': 6, '23.07': 6, '23.06': 6, '23.05': 6, '23.04': 6, '23.03': 6, '23.02': 6, '23.01': 6,
                '23.00': 7, '22.59': 7, '22.58': 7, '22.57': 7, '22.56': 7, '22.55': 7, '22.54': 7, '22.53': 7, '22.52': 7, '22.51': 7,
                '22.50': 8, '22.49': 8, '22.48': 8, '22.47': 8, '22.46': 8, '22.45': 8, '22.44': 8, '22.43': 8, '22.42': 8, '22.41': 8,
                '22.40': 9, '22.39': 9, '22.38': 9, '22.37': 9, '22.36': 9, '22.35': 9, '22.34': 9, '22.33': 9, '22.32': 9, '22.31': 9,
                '22.30': 10, '22.29': 10, '22.28': 10, '22.27': 10, '22.26': 10, '22.25': 10, '22.24': 10, '22.23': 10, '22.22': 10, '22.21': 10,
                '22.20': 11, '22.19': 11, '22.18': 11, '22.17': 11, '22.16': 11, '22.15': 11, '22.14': 11, '22.13': 11, '22.12': 11, '22.11': 11,
                '22.10': 12, '22.09': 12, '22.08': 12, '22.07': 12, '22.06': 12, '22.05': 12, '22.04': 12, '22.03': 12, '22.02': 12, '22.01': 12,
                '22.00': 13, '21.59': 13, '21.58': 13, '21.57': 13, '21.56': 13, '21.55': 13, '21.54': 13, '21.53': 13, '21.52': 13, '21.51': 13,
                '21.50': 14, '21.49': 14, '21.48': 14, '21.47': 14, '21.46': 14, '21.45': 14, '21.44': 14, '21.43': 14, '21.42': 14, '21.41': 14,
                '21.40': 15, '21.39': 15, '21.38': 15, '21.37': 15, '21.36': 15, '21.35': 15, '21.34': 15, '21.33': 15, '21.32': 15, '21.31': 15,
                '21.30': 16, '21.29': 16, '21.28': 16, '21.27': 16, '21.26': 16, '21.25': 16, '21.24': 16, '21.23': 16, '21.22': 16, '21.21': 16,
                '21.20': 17, '21.19': 17, '21.18': 17, '21.17': 17, '21.16': 17, '21.15': 17, '21.14': 17, '21.13': 17, '21.12': 17, '21.11': 17,
                '21.10': 18, '21.09': 18, '21.08': 18, '21.07': 18, '21.06': 18, '21.05': 18, '21.04': 18, '21.03': 18, '21.02': 18, '21.01': 18,
                '21.00': 19, '20.59': 19, '20.58': 19, '20.57': 19, '20.56': 19, '20.55': 19, '20.54': 19, '20.53': 19, '20.52': 19, '20.51': 19,
                '20.50': 20, '20.49': 20, '20.48': 20, '20.47': 20, '20.46': 20, '20.45': 20, '20.44': 20, '20.43': 20, '20.42': 20, '20.41': 20,
                '20.40': 21, '20.39': 21, '20.38': 21, '20.37': 21, '20.36': 21, '20.35': 21, '20.34': 21, '20.33': 21, '20.32': 21, '20.31': 21,
                '20.30': 22, '20.29': 22, '20.28': 22, '20.27': 22, '20.26': 22, '20.25': 22, '20.24': 22, '20.23': 22, '20.22': 22, '20.21': 22,
                '20.20': 23, '20.19': 23, '20.18': 23, '20.17': 23, '20.16': 23, '20.15': 23, '20.14': 23, '20.13': 23, '20.12': 23, '20.11': 23,
                '20.10': 24, '20.09': 24, '20.08': 24, '20.07': 24, '20.06': 24, '20.05': 24, '20.04': 24, '20.03': 24, '20.02': 24, '20.01': 24,
                '20.00': 25
            },
            "II": {
                '24.20': 1, '24.19': 1, '24.18': 1, '24.17': 1, '24.16': 1, '24.15': 1, '24.14': 1, '24.13': 1, '24.12': 1, '24.11': 1,
                '24.10': 2, '24.09': 2, '24.08': 2, '24.07': 2, '24.06': 2, '24.05': 2, '24.04': 2, '24.03': 2, '24.02': 2, '24.01': 2,
                '24.00': 3, '23.59': 3, '23.58': 3, '23.57': 3, '23.56': 3, '23.55': 3, '23.54': 3, '23.53': 3, '23.52': 3, '23.51': 3,
                '23.50': 4, '23.49': 4, '23.48': 4, '23.47': 4, '23.46': 4, '23.45': 4, '23.44': 4, '23.43': 4, '23.42': 4, '23.41': 4,
                '23.40': 5, '23.39': 5, '23.38': 5, '23.37': 5, '23.36': 5, '23.35': 5, '23.34': 5, '23.33': 5, '23.32': 5, '23.31': 5,
                '23.30': 6, '23.29': 6, '23.28': 6, '23.27': 6, '23.26': 6, '23.25': 6, '23.24': 6, '23.23': 6, '23.22': 6, '23.21': 6,
                '23.20': 7, '23.19': 7, '23.18': 7, '23.17': 7, '23.16': 7, '23.15': 7, '23.14': 7, '23.13': 7, '23.12': 7, '23.11': 7,
                '23.10': 8, '23.09': 8, '23.08': 8, '23.07': 8, '23.06': 8, '23.05': 8, '23.04': 8, '23.03': 8, '23.02': 8, '23.01': 8,
                '23.00': 9, '22.59': 9, '22.58': 9, '22.57': 9, '22.56': 9, '22.55': 9, '22.54': 9, '22.53': 9, '22.52': 9, '22.51': 9,
                '22.50': 10, '22.49': 10, '22.48': 10, '22.47': 10, '22.46': 10, '22.45': 10, '22.44': 10, '22.43': 10, '22.42': 10, '22.41': 10,
                '22.40': 11, '22.39': 11, '22.38': 11, '22.37': 11, '22.36': 11, '22.35': 11, '22.34': 11, '22.33': 11, '22.32': 11, '22.31': 11,
                '22.30': 12, '22.29': 12, '22.28': 12, '22.27': 12, '22.26': 12, '22.25': 12, '22.24': 12, '22.23': 12, '22.22': 12, '22.21': 12,
                '22.20': 13, '22.19': 13, '22.18': 13, '22.17': 13, '22.16': 13, '22.15': 13, '22.14': 13, '22.13': 13, '22.12': 13, '22.11': 13,
                '22.10': 14, '22.09': 14, '22.08': 14, '22.07': 14, '22.06': 14, '22.05': 14, '22.04': 14, '22.03': 14, '22.02': 14, '22.01': 14,
                '22.00': 15, '21.59': 15, '21.58': 15, '21.57': 15, '21.56': 15, '21.55': 15, '21.54': 15, '21.53': 15, '21.52': 15, '21.51': 15,
                '21.50': 16, '21.49': 16, '21.48': 16, '21.47': 16, '21.46': 16, '21.45': 16, '21.44': 16, '21.43': 16, '21.42': 16, '21.41': 16,
                '21.40': 17, '21.39': 17, '21.38': 17, '21.37': 17, '21.36': 17, '21.35': 17, '21.34': 17, '21.33': 17, '21.32': 17, '21.31': 17,
                '21.30': 18, '21.29': 18, '21.28': 18, '21.27': 18, '21.26': 18, '21.25': 18, '21.24': 18, '21.23': 18, '21.22': 18, '21.21': 18,
                '21.20': 19, '21.19': 19, '21.18': 19, '21.17': 19, '21.16': 19, '21.15': 19, '21.14': 19, '21.13': 19, '21.12': 19, '21.11': 19,
                '21.10': 20, '21.09': 20, '21.08': 20, '21.07': 20, '21.06': 20, '21.05': 20, '21.04': 20, '21.03': 20, '21.02': 20, '21.01': 20,
                '21.00': 21, '20.59': 21, '20.58': 21, '20.57': 21, '20.56': 21, '20.55': 21, '20.54': 21, '20.53': 21, '20.52': 21, '20.51': 21,
                '20.50': 22, '20.49': 22, '20.48': 22, '20.47': 22, '20.46': 22, '20.45': 22, '20.44': 22, '20.43': 22, '20.42': 22, '20.41': 22,
                '20.40': 23, '20.39': 23, '20.38': 23, '20.37': 23, '20.36': 23, '20.35': 23, '20.34': 23, '20.33': 23, '20.32': 23, '20.31': 23,
                '20.30': 24, '20.29': 24, '20.28': 24, '20.27': 24, '20.26': 24, '20.25': 24, '20.24': 24, '20.23': 24, '20.22': 24, '20.21': 24,
                '20.20': 25
            },
            "III": {
                '25.20': 1, '25.19': 1, '25.18': 1, '25.17': 1, '25.16': 1, '25.15': 1, '25.14': 1, '25.13': 1, '25.12': 1, '25.11': 1,
                '25.10': 2, '25.09': 2, '25.08': 2, '25.07': 2, '25.06': 2, '25.05': 2, '25.04': 2, '25.03': 2, '25.02': 2, '25.01': 2,
                '25.00': 3, '24.59': 3, '24.58': 3, '24.57': 3, '24.56': 3, '24.55': 3, '24.54': 3, '24.53': 3, '24.52': 3, '24.51': 3,
                '24.50': 4, '24.49': 4, '24.48': 4, '24.47': 4, '24.46': 4, '24.45': 4, '24.44': 4, '24.43': 4, '24.42': 4, '24.41': 4,
                '24.40': 5, '24.39': 5, '24.38': 5, '24.37': 5, '24.36': 5, '24.35': 5, '24.34': 5, '24.33': 5, '24.32': 5, '24.31': 5,
                '24.30': 6, '24.29': 6, '24.28': 6, '24.27': 6, '24.26': 6, '24.25': 6, '24.24': 6, '24.23': 6, '24.22': 6, '24.21': 6,
                '24.20': 7, '24.19': 7, '24.18': 7, '24.17': 7, '24.16': 7, '24.15': 7, '24.14': 7, '24.13': 7, '24.12': 7, '24.11': 7,
                '24.10': 8, '24.09': 8, '24.08': 8, '24.07': 8, '24.06': 8, '24.05': 8, '24.04': 8, '24.03': 8, '24.02': 8, '24.01': 8,
                '24.00': 9, '23.59': 9, '23.58': 9, '23.57': 9, '23.56': 9, '23.55': 9, '23.54': 9, '23.53': 9, '23.52': 9, '23.51': 9,
                '23.50': 10, '23.49': 10, '23.48': 10, '23.47': 10, '23.46': 10, '23.45': 10, '23.44': 10, '23.43': 10, '23.42': 10, '23.41': 10,
                '23.40': 11, '23.39': 11, '23.38': 11, '23.37': 11, '23.36': 11, '23.35': 11, '23.34': 11, '23.33': 11, '23.32': 11, '23.31': 11,
                '23.30': 12, '23.29': 12, '23.28': 12, '23.27': 12, '23.26': 12, '23.25': 12, '23.24': 12, '23.23': 12, '23.22': 12, '23.21': 12,
                '23.20': 13, '23.19': 13, '23.18': 13, '23.17': 13, '23.16': 13, '23.15': 13, '23.14': 13, '23.13': 13, '23.12': 13, '23.11': 13,
                '23.10': 14, '23.09': 14, '23.08': 14, '23.07': 14, '23.06': 14, '23.05': 14, '23.04': 14, '23.03': 14, '23.02': 14, '23.01': 14,
                '23.00': 15, '22.59': 15, '22.58': 15, '22.57': 15, '22.56': 15, '22.55': 15, '22.54': 15, '22.53': 15, '22.52': 15, '22.51': 15,
                '22.50': 16, '22.49': 16, '22.48': 16, '22.47': 16, '22.46': 16, '22.45': 16, '22.44': 16, '22.43': 16, '22.42': 16, '22.41': 16,
                '22.40': 17, '22.39': 17, '22.38': 17, '22.37': 17, '22.36': 17, '22.35': 17, '22.34': 17, '22.33': 17, '22.32': 17, '22.31': 17,
                '22.30': 18, '22.29': 18, '22.28': 18, '22.27': 18, '22.26': 18, '22.25': 18, '22.24': 18, '22.23': 18, '22.22': 18, '22.21': 18,
                '22.20': 19, '22.19': 19, '22.18': 19, '22.17': 19, '22.16': 19, '22.15': 19, '22.14': 19, '22.13': 19, '22.12': 19, '22.11': 19,
                '22.10': 20, '22.09': 20, '22.08': 20, '22.07': 20, '22.06': 20, '22.05': 20, '22.04': 20, '22.03': 20, '22.02': 20, '22.01': 20,
                '22.00': 21, '21.59': 21, '21.58': 21, '21.57': 21, '21.56': 21, '21.55': 21, '21.54': 21, '21.53': 21, '21.52': 21, '21.51': 21,
                '21.50': 22, '21.49': 22, '21.48': 22, '21.47': 22, '21.46': 22, '21.45': 22, '21.44': 22, '21.43': 22, '21.42': 22, '21.41': 22,
                '21.40': 23, '21.39': 23, '21.38': 23, '21.37': 23, '21.36': 23, '21.35': 23, '21.34': 23, '21.33': 23, '21.32': 23, '21.31': 23,
                '21.30': 24, '21.29': 24, '21.28': 24, '21.27': 24, '21.26': 24, '21.25': 24, '21.24': 24, '21.23': 24, '21.22': 24, '21.21': 24,
                '21.20': 25
            }
        },

        '7a-Mashq': {
            'I': {
                '14.10': 1, '14.09': 1, '14.08': 2, '14.07': 3, '14.06': 3, '14.05': 3, '14.04': 4, '14.03': 4, '14.02': 4,
                '14.01': 5, '14.00': 5, '13.59': 5, '13.58': 6, '13.57': 6, '13.56': 6, '13.55': 7, '13.54': 7, '13.53': 7,
                '13.52': 8, '13.51': 8, '13.50': 8, '13.49': 9, '13.48': 9, '13.47': 9, '13.46': 10, '13.45': 10, '13.44': 10,
                '13.43': 11, '13.42': 11, '13.41': 11, '13.40': 12, '13.39': 12, '13.38': 12, '13.37': 12, '13.36': 12, '13.35': 13,
                '13.34': 13, '13.33': 13, '13.32': 13, '13.31': 13, '13.30': 14, '13.29': 14, '13.28': 14, '13.27': 14, '13.26': 14,
                '13.25': 15, '13.24': 15, '13.23': 15, '13.22': 15, '13.21': 15, '13.20': 16, '13.19': 16, '13.18': 16, '13.17': 16,
                '13.16': 16, '13.15': 17, '13.14': 17, '13.13': 17, '13.12': 17, '13.11': 17, '13.10': 18, '13.09': 18, '13.08': 18,
                '13.07': 18, '13.06': 18, '13.05': 19, '13.04': 19, '13.03': 19, '13.02': 19, '13.01': 19, '13.00': 20, '12.59': 20,
                '12.58': 20, '12.57': 20, '12.56': 20, '12.55': 21, '12.54': 21, '12.53': 21, '12.52': 21, '12.51': 21, '12.50': 22,
                '12.49': 22, '12.48': 22, '12.47': 22, '12.46': 22, '12.45': 23, '12.44': 23, '12.43': 23, '12.42': 23, '12.41': 23,
                '12.40': 24, '12.39': 24, '12.38': 24, '12.37': 24, '12.36': 24, '12.35': 25
            },
            'II': {
                '14.25': 1, '14.24': 1, '14.23': 1, '14.22': 1, '14.21': 1, '14.20': 2, '14.19': 2, '14.18': 2, '14.17': 3, '14.16': 3,
                '14.15': 3, '14.14': 4, '14.13': 4, '14.12': 4, '14.11': 5, '14.10': 5, '14.09': 5, '14.08': 6, '14.07': 6,
                '14.06': 6, '14.05': 7, '14.04': 7, '14.03': 7, '14.02': 8, '14.01': 8, '14.00': 8, '13.59': 8, '13.58': 9,
                '13.57': 9, '13.56': 10, '13.55': 10, '13.54': 10, '13.53': 11, '13.52': 11, '13.51': 11, '13.50': 12, '13.49': 12,
                '13.48': 12, '13.47': 12, '13.46': 12, '13.45': 13, '13.44': 13, '13.43': 13, '13.42': 13, '13.41': 13, '13.40': 14,
                '13.39': 14, '13.38': 14, '13.37': 14, '13.36': 14, '13.35': 15, '13.34': 15, '13.33': 15, '13.32': 15, '13.31': 15,
                '13.30': 16, '13.29': 16, '13.28': 16, '13.27': 16, '13.26': 16, '13.25': 17, '13.24': 17, '13.23': 17, '13.22': 17,
                '13.21': 17, '13.20': 18, '13.19': 18, '13.18': 18, '13.17': 18, '13.16': 18, '13.15': 19, '13.14': 19, '13.13': 19,
                '13.12': 19, '13.11': 19, '13.10': 20, '13.09': 20, '13.08': 20, '13.07': 20, '13.06': 20, '13.05': 21, '13.04': 21,
                '13.03': 21, '13.02': 21, '13.01': 21, '13.00': 22, '12.59': 22, '12.58': 22, '12.57': 22, '12.56': 22, '12.55': 23,
                '12.54': 23, '12.53': 23, '12.52': 23, '12.51': 23, '12.50': 24, '12.49': 24, '12.48': 24, '12.47': 24, '12.46': 24,
                '12.45': 25
            },
        },

        '9-Mashq': {
            'I': {
                '8': 1, '9': 3, '10': 5, '11': 7, '12': 9, '14': 11, '15': 13, '16': 15, '17': 17, '18': 19,
                '19': 20, '20': 21, '21': 22, '22': 23, '23': 24, '24': 25, '25': 26
            },
            'II': {
                '7': 1, '8': 3, '9': 5, '10': 7, '11': 9, '12': 11, '13': 13, '14': 15, '15': 17, '16': 19, '17': 20,
                '18': 21, '19': 22, '20': 23, '21': 24, '22': 25, '23': 26, '24': 27, '25': 28
            },
            'III': {
                '4': 1, '5': 3, '6': 5, '7': 7, '8': 9, '9': 11, '10': 13, '11': 15, '12': 17, '13': 19,
                '14': 21, '15': 22, '16': 23, '17': 24, '18': 25, '19': 26, '20': 27, '21': 28, '22': 29, '23': 30, '24': 31, '25': 32
            },
            'IV': {
                '2': 1, '3': 3, '4': 5, '5': 7, '6': 9, '7': 11, '8': 13, '9': 15, '10': 17, '11': 19,
                '12': 21, '13': 22, '14': 23, '15': 24, '16': 25, '17': 26, '18': 27, '19': 28, '20': 29, '21': 30, '22': 31, '23': 32, '24': 33, '25': 34, '26': 35
            },
            'V': {
                '1': 1, '2': 4, '3': 5, '4': 8, '5': 11, '6': 14, '7': 17, '8': 20, '9': 22, '10': 24,
                '11': 26, '12': 28, '13': 30, '14': 32, '15': 34, '16': 36, '17': 38, '18': 40, '19': 42, '20': 44, '21': 46, '22': 48, '23': 50, '24': 52, '25': 54, '26': 56, '27': 58
            },
            'VI': {
                '1': 1, '2': 4, '3': 5, '4': 8, '5': 11, '6': 14, '7': 17, '8': 20, '9': 22, '10': 24,
                '11': 26, '12': 28, '13': 30, '14': 32, '15': 34, '16': 36, '17': 38, '18': 40, '19': 42, '20': 44, '21': 46, '22': 48, '23': 50, '24': 52, '25': 54, '26': 56, '27': 58
            },
            'VII': {
                '1': 1, '2': 4, '3': 5, '4': 8, '5': 11, '6': 14, '7': 17, '8': 20, '9': 22, '10': 24,
                '11': 26, '12': 28, '13': 30, '14': 32, '15': 34, '16': 36, '17': 38, '18': 40, '19': 42, '20': 44, '21': 46, '22': 48, '23': 50, '24': 52, '25': 54, '26': 56, '27': 58
            }
        },
        '26-Mashq': {
            'I': {
                '2.24': 1, '2.23': 2, '2.22': 3, '2.21': 4, '2.20': 5, '2.19': 6, '2.18': 7, '2.17': 8, '2.16': 9,
                '2.15': 10, '2.14': 11, '2.13': 12, '2.12': 13, '2.11': 14, '2.10': 15, '2.09': 16, '2.08': 17,
                '2.07': 17, '2.06': 17, '2.05': 18, '2.04': 19, '2.03': 19, '2.02': 20, '2.01': 20, '2.00': 21,
                '1.59': 21, '1.58': 22, '1.57': 22, '1.56': 23, '1.55': 23, '1.54': 24, '1.53': 24, '1.52': 25
            },
            'II': {
                '2.29': 1, '2.28': 2, '2.27': 3, '2.26': 4, '2.25': 5, '2.24': 6, '2.23': 7, '2.22': 8, '2.21': 9,
                '2.20': 10, '2.19': 11, '2.18': 12, '2.17': 13, '2.16': 14, '2.15': 15, '2.14': 16, '2.13': 17,
                '2.12': 17, '2.11': 18, '2.10': 18, '2.09': 19, '2.08': 19, '2.07': 20, '2.06': 20, '2.05': 21,
                '2.04': 21, '2.03': 22, '2.02': 22, '2.01': 23, '2.00': 23, '1.59': 24, '1.58': 24, '1.57': 25
            },
            'III': {
                '2.39': 1, '2.38': 2, '2.37': 3, '2.36': 4, '2.35': 5, '2.34': 6, '2.33': 7, '2.32': 8, '2.31': 9,
                '2.30': 10, '2.29': 11, '2.28': 12, '2.27': 13, '2.26': 14, '2.25': 15, '2.24': 16, '2.23': 17,
                '2.22': 17, '2.21': 18, '2.20': 18, '2.19': 19, '2.18': 19, '2.17': 20, '2.16': 20, '2.15': 21,
                '2.14': 21, '2.13': 22, '2.12': 22, '2.11': 23, '2.10': 23, '2.09': 24, '2.08': 24, '2.07': 25,
            },
            'IV': {

            }
        },
        '10-Mashq': {
            'I': {
                '4': 1, '5': 4, '6': 7, '7': 8, '8': 9, '10': 16, '11': 19, '12': 20, '13': 21, '14': 22, '15': 23, '16': 24, '17': 25
            },
            'II': {
                '3': 1, '5': 7, '6': 10, '7': 13, '8': 15, '9': 17, '10': 19, '11': 21, '12': 22, '13': 23, '14': 24, '15': 25
            },
            "III": {
                '4': 1, '5': 4, '6': 5, '7': 7, '8': 9, '9': 11, '10': 13, '11': 15, '12': 17, '13': 19, '14': 21, '15': 22, '16': 23, '17': 24, '18': 25
            },
            "IV": {
                '1': 1, '2': 5, '3': 9, '4': 12, '5': 15, '6': 17, '8': 21, '9': 23, '10': 25
            }
        }
    };

    const ageGroupScores = scoreMap[exerciseName] || {};
    const resultScores = ageGroupScores[ageGroup] || {};

    return resultScores[result] || 0;
};

export const calculateTotalScore = (ageGroup, exercises) => {
    return exercises.reduce((total, exercise) => {
        return total + calculateScore(exercise.exerciseName, exercise.result, ageGroup);
    }, 0);
};
